<template>
  <section
    id="popNotification"
    class="pop notification"
    @click.prevent="closePopup"
  >
    <div class="popBody" @click.stop>
      <a class="close" @click.prevent="closePopup"
        ><font-awesome-icon
          icon="fa-solid fa-xmark"
          size="lg"
        ></font-awesome-icon
      ></a>
      <span class="title">通知</span>
      <a class="read" @click.prevent="readAll">
        <span>全部已讀</span>
      </a>
      <a
        class="item"
        v-for="(notification, index) in notifications"
        :key="'notification' + index"
        @click.stop="readItem(notification.id, notification.unread)"
      >
        <div :class="['dot', { active: notification.unread }]"></div>
        <div class="left">
          <span>{{ notification.title }}</span>
          <span v-html="notification.text"></span>
          <span>{{ tsToDatetime(notification.ts) }}</span>
        </div>
        <!-- <div class="right">
          <div>
            <span>800</span>
            <img src="@/assets/icon/mPoint.svg" />
          </div>
        </div> -->
      </a>
      <!-- <a class="item">
        <div class="dot active"></div>
        <div class="left">
          <span>獲得優惠券</span>
          <span>優惠券名稱</span>
          <span>2022-01-15 10:30</span>
        </div>
        <div class="right">
          <div>
            <span>800</span>
            <img src="@/assets/icon/mTicket.svg" />
          </div>
        </div>
      </a> -->
    </div>
  </section>
</template>
<script>
import moment from 'moment-timezone';
import { mapState } from 'vuex';

export default {
  name: 'NotificationPopup',
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      latestReadAt: 0,
    };
  },
  mounted() {
    popNotification.style.display = 'flex';
    document.body.style.overflow = 'hidden';
  },
  beforeDestroy() {
    document.body.style.overflow = 'scroll';
  },
  computed: {
    ...mapState(['user', 'userInfo']),
    merchantId() {
      return this.user.user?._json?.merchantId || this.userInfo.merchantId;
    },
    notifications() {
      let ns = this.$store.getters['noti/notifications'];
      return Object.values(ns).sort((a, b) => {
        return b.ts - a.ts;
      });
    },
  },
  methods: {
    tsToDatetime(ts) {
      return moment(ts * 1000).format('YYYY-MM-DD HH:mm:ss');
    },
    updateUnreads(_latestReadAt) {
      if (_latestReadAt) this.latestReadAt = _latestReadAt;
      this.notifications.forEach(noti => {
        if (noti.ts <= this.latestReadAt) noti.unread = false;
      });
    },
    readItem(docId, unread) {
      if (!unread) return;
      //NOTE: Howard's fix: use API to mark read instead of writing Firestore directly.
      let config = {
        url: `${this.apiHost}/air3/v3/merchants/${this.merchantId}/firestore-mark-read-legacy`,
        method: 'POST',
        data: {
          topicId: this.user.user.firestoreChannelId,
          messageId: docId,
        },
      };
      return this.$http(config);
    },
    readAll() {
      //NOTE: Howard's fix: use API to mark all read instead of writing Firestore directly.
      let config = {
        url: `${this.apiHost}/air3/v3/merchants/${this.merchantId}/firestore-mark-topic-read`,
        method: 'POST',
        data: {
          topicId: this.user.user.firestoreChannelId,
          uuid: this.user['userId'],
        },
      };
      this.updateUnreads(moment().unix()); //Manually mark all current notifications "read".
      this.$store.commit('noti/setUnreadCount', 0);
      return this.$http(config);
    },
    closePopup() {
      popNotification.children[0].style.animationName = 'popdown';
      setTimeout(() => {
        this.$emit('closePopup');
        popNotification.children[0].style.animationName = 'popup';
      }, 500);
    },
  },
};
</script>
