<template>
  <div class="scanCode">
    <Spinner :spin="spin" />
    <section class="main">
      <div class="top">
        <div class="icon"><img src="@/assets/icon/mStamp.svg" /></div>
        <span class="subtitle">{{ portalInfo.title }}</span>
        <span class="title">{{ title }}</span>
        <span>{{ description }}</span>
      </div>
      <div class="action">
        <div class="bg"></div>
        <button v-if="showLoginButton" @click="goSignin">
          <span>登入集點</span>
        </button>
        <button v-else @click="goTo('/home')">
          <span>查看集點卡</span>
        </button>
        <span
          v-if="
            showLoginButton ||
            orderInfo.data?.code === 'SUCCESS' ||
            orderInfo.data?.code === 'ORDER_EXISTS'
          "
          class="remark"
          >集點僅提供給{{
            showLoginButton || orderInfo.isAnonymous ? '登入' : '訂購'
          }}者</span
        >
        <span class="remark"
          >線上訂單掃描條碼後將自動歸戶給訂購者
          <br />現場訂單掃描條碼後需登入會員方可完成歸戶</span
        >
        <div class="service">
          <span>服務由</span>
          <img src="@/assets/icon/faviconW.svg" />
          <span>提供</span>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Spinner from '../components/Spinner.vue';

export default {
  components: { Spinner },
  name: 'RewardCollection',
  beforeRouteLeave(to, from, next) {
    this.spin = false;
    next();
  },
  data() {
    return {
      qcApiHost: process.env.VUE_APP_QCAPI_HOST,
      orderId: null,
      hv: null,
      ts: null,
      orderInfo: {},
      title: '',
      description: '',
      showLoginButton: false,
      spin: true,
    };
  },
  computed: {
    ...mapState(['user']),
    ...mapState('portal', ['portalInfo']),
  },
  async mounted() {
    const params = new URLSearchParams(window.location.search);
    this.orderId = params.get('orderId');
    this.hv = params.get('hv');
    this.ts = params.get('ts');

    if (!this.orderId || !this.hv || !this.ts) {
      this.goTo('/error');
      return;
    }

    await qcsso.init({
      appId: this.portalInfo.sso.unverifyAppId,
    });

    if (qcsso.isLoggedIn()) {
      const user = await qcsso.getProfile().catch(err => console.error(err));
      this.$store.commit('updateUser', user);
    }
    this.checkOrder();
  },
  methods: {
    goTo(path) {
      const query = { portal_key: this.$route.query.portal_key };
      const shopId = this.$route.query.shop_id;
      if (shopId) query['shop_id'] = shopId;

      this.$router.push({ path, query });
    },
    goSignin() {
      qcsso.login({
        redirectUrl: window.location,
      });
    },
    callCheckOrderAPI() {
      const data = {
        orderId: this.orderId,
        hv: this.hv,
        ts: this.ts,
      };

      if (this.user?.userId) {
        data.userId = this.user.userId;
      }

      let config = {
        url: `${this.qcApiHost}/console/apis/pub/qrcode/orders/${this.orderId}/check`,
        method: 'POST',
        data,
      };
      return this.$http(config);
    },
    checkOrder() {
      this.callCheckOrderAPI()
        .then(res => {
          console.log('checkOrder res data:', res.data);

          switch (res.data.data?.code) {
            case 'SUCCESS':
              this.title = '集點成功！';
              this.description = '登入會員查看獎勵';
              break;
            case 'ORDER_EXISTS':
              this.title = '已領取集點！';
              this.description = '登入會員查看獎勵';
              break;
            case 'NO_NEED_SEND':
              this.title = '未達回饋門檻';
              this.description = '登入會員查看集點規則';
              break;
            case 'NO_ACTIVE_CAMPAIGN':
              this.title = '無進行的活動';
              this.description = '登入會員中心';
              break;  
            default:
              this.title = '集點失敗';
              this.description = res.data.data.code;
              break;
          }

          this.orderInfo = res.data;
        })
        .catch(err => {
          console.error(err);

          if (!err.response?.data?.code) {
            this.goTo('/error');
            return;
          }

          switch (err.response?.data?.code) {
            case 'QR001':
              this.title = '集點失敗';
              this.description = '無效的QR Code';
              break;
            case 'QR002':
              this.title = '集點失敗';
              this.description = '無此筆訂單資訊';
              break;
            case 'QR003':
              this.title = '您有新的集點！';
              this.description = '即刻登入會員集點';
              this.showLoginButton = true;
              break;
            default:
              this.title = '集點失敗';
              this.description = err.response.data.message;
              break;
          }
        })
        .finally(() => (this.spin = false));
    },
  },
};
</script>

<style src="@/assets/css/qcard.css"></style>
