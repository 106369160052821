var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scanCode"},[_c('Spinner',{attrs:{"spin":_vm.spin}}),_c('section',{staticClass:"main"},[_c('div',{staticClass:"top"},[_vm._m(0),_c('span',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.portalInfo.title))]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),_c('span',[_vm._v(_vm._s(_vm.description))])]),_c('div',{staticClass:"action"},[_c('div',{staticClass:"bg"}),(_vm.showLoginButton)?_c('button',{on:{"click":_vm.goSignin}},[_c('span',[_vm._v("登入集點")])]):_c('button',{on:{"click":function($event){return _vm.goTo('/home')}}},[_c('span',[_vm._v("查看集點卡")])]),(
          _vm.showLoginButton ||
          _vm.orderInfo.data?.code === 'SUCCESS' ||
          _vm.orderInfo.data?.code === 'ORDER_EXISTS'
        )?_c('span',{staticClass:"remark"},[_vm._v("集點僅提供給"+_vm._s(_vm.showLoginButton || _vm.orderInfo.isAnonymous ? '登入' : '訂購')+"者")]):_vm._e(),_vm._m(1),_vm._m(2)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/icon/mStamp.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"remark"},[_vm._v("線上訂單掃描條碼後將自動歸戶給訂購者 "),_c('br'),_vm._v("現場訂單掃描條碼後需登入會員方可完成歸戶")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service"},[_c('span',[_vm._v("服務由")]),_c('img',{attrs:{"src":require("@/assets/icon/faviconW.svg")}}),_c('span',[_vm._v("提供")])])
}]

export { render, staticRenderFns }