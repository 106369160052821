<template>
  <fragment>
    <section class="coupon">
      <a v-if="!stampCards.length" class="coupon gift">
        <div class="dot left"></div>
        <div class="dot right"></div>
        <span class="none">暫無集點卡</span>
      </a>
      <fragment v-else>
        <div class="top">
          <div class="header">
            <div>
              <a @click.prevent="isMM && stampCardRule && popup('stamp-rule')"
                :style="{ 'pointer-events': !(isMM && stampCardRule) ? 'none': ''}">
                <span>{{ stamp.numOfStamps || 0 }}</span>
                <span>點</span>
              </a>
            </div>
            <a @click.prevent="popup('stamps')">
              <span>{{ selectedStampCard.stampCardName }}</span>
              <font-awesome-icon
                v-if="stampCards.length > 1"
                icon="fa-solid fa-caret-down"
              ></font-awesome-icon>
            </a>
          </div>
          <span v-if="isMM" class="remark"> 
            {{ tsToDate(stamp.collectStartTime) }} -
            {{ tsToDate(stamp.collectEndTime) }} 集點
          </span>
          <span class="remark">
            {{ tsToDate(stamp.redeemStartTime) }} -
            {{ tsToDate(stamp.redeemEndTime) }} 兌換
          </span>
          <span class="content">{{ stampCardDesc }}</span>
          <div class="buttons">
            <a v-if="isMM && stampCardRule" @click.prevent="popup('stamp-rule')">
              <font-awesome-icon icon="fa-solid fa-coins"></font-awesome-icon>
              <span>集點規則</span>
            </a>
            <a
              v-if="isMM && stamp.stampCardId"
              @click="goTo(`/stamp-card-record/${stamp.stampCardId}`)"
            >
              <font-awesome-icon icon="fa-solid fa-list-ul"></font-awesome-icon>
              <span>交易紀錄</span>
            </a>
            <a v-if="stampCardNotice" @click.prevent="popup('stamp-notice')">
              <font-awesome-icon
                icon="fa-solid fa-circle-info"
              ></font-awesome-icon>
              <span>注意事項</span>
            </a>
          </div>
        </div>
        <span class="title">兌換獎項</span>

        <a
          class="coupon gift"
          v-if="!stamp.rewards?.length || !stamp.rewards[0].rewardId"
        >
          <div class="dot left"></div>
          <div class="dot right"></div>
          <span class="none">暫無獎品</span>
        </a>
        <a
          v-else
          class="coupon gift"
          v-for="(reward, index) in stamp.rewards"
          :key="'reward' + index"
          @click.prevent="popGift(reward)"
        >
          <div class="dot left"></div>
          <div class="dot right"></div>
          <div class="content">
            <span class="title">{{ reward.rewardName }}</span>
            <span class="info">{{ reward.rewardDesc }}</span>
            <span class="point"
              >兌換點數：{{ reward.rewardRequirement }}點</span
            >
          </div>
          <img v-if="reward.rewardImageUrl" :src="reward.rewardImageUrl" />
        </a>
      </fragment>
    </section>

    <!-- 集點卡列表popup -->
    <section
      id="popStamps"
      class="pop stamps"
      @click.prevent="popClose('stamps')"
      ref="stamps"
    >
      <div class="popBody" @click.stop>
        <a class="close" @click.prevent="popClose('stamps')"
          ><font-awesome-icon
            icon="fa-solid fa-xmark"
            size="lg"
          ></font-awesome-icon
        ></a>
        <span class="title">集點卡</span>
        <a
          v-for="(stampCard, index) in stampCards"
          :key="'card_' + index"
          :class="{
            active: stampCard.stampCardId === selectedStampCard.stampCardId,
          }"
          @click.prevent="selectStampCard(stampCard)"
        >
          <span>{{ stampCard.stampCardName }}</span>
        </a>
      </div>
    </section>

    <!-- 集點規則popup -->
    <section
      id="popStampRule"
      class="pop pointRule"
      @click.prevent="popClose('stamp-rule')"
      ref="stamp-rule"
    >
      <div class="popBody" @click.stop>
        <div>
          <div class="top">
            <span>集點方式</span>
          </div>
          <div class="bottom">
            <span
              >現場{{ onlineCollection ? '/線上' : '' }}<br />每消費NT${{
                stampCardRule?.purchase
              }}</span
            >
            <font-awesome-icon
              icon="fa-solid fa-chevron-right"
              size="lg"
            ></font-awesome-icon>
            <span>贈送{{ stampCardRule?.accrualAmount }}點</span>
          </div>
        </div>
        <div>
          <div class="top">
            <span>獎勵兌換</span>
          </div>
          <div class="bottom">
            <span>集點滿額</span>
            <font-awesome-icon
              icon="fa-solid fa-chevron-right"
              size="lg"
            ></font-awesome-icon>
            <span>可於現場<br />兌換獎勵</span>
          </div>
        </div>
      </div>
    </section>

    <!-- 集點注意事項popup -->
    <section
      id="popStampNotice"
      class="pop notice stampRule"
      @click.prevent="popClose('stamp-notice')"
      ref="stamp-notice"
    >
      <div class="popBody" @click.stop>
        <a class="close" @click.prevent="popClose('stamp-notice')"
          ><font-awesome-icon
            icon="fa-solid fa-xmark"
            size="lg"
          ></font-awesome-icon
        ></a>
        <div>
          <span class="title">注意事項</span>
          <textarea :value="stampCardNotice" readonly></textarea>
        </div>
      </div>
    </section>

    <!-- 集點獎品popup -->
    <section
      id="popGift"
      class="pop gift"
      @click.prevent="popClose('gift')"
      ref="gift"
    >
      <div class="popBody" @click.stop>
        <a class="close" @click.prevent="popClose('gift')"
          ><font-awesome-icon
            icon="fa-solid fa-xmark"
            size="lg"
          ></font-awesome-icon
        ></a>
        <img v-if="reward.rewardImageUrl" :src="reward.rewardImageUrl" />
        <div>
          <span class="title">{{ reward.rewardName }}</span>
          <span>{{ reward.rewardDesc }}</span>
          <div class="sticker">
            <span>{{ reward.rewardRequirement }}點</span>
          </div>
        </div>
        <!-- ********** 按鈕擇一 ********** -->
        <button v-if="checkUnAvailableNumOfStamp(reward)" class="disabled">
          <span>點數不足</span>
        </button>
        <button v-else-if="checkUnAvailableTime()" class="disabled">
          <span>已截止</span>
        </button>
        <button
          v-else-if="checkAvailableToGet(reward)"
          @click.prevent="
            userInfo.phoneVerified ? popGiftGet(reward) : popup('notice')
          "
        >
          <span>兌換</span>
        </button>
      </div>
    </section>

    <!-- 確認兌換popup -->
    <section
      id="popConfirm"
      class="pop notice confirm"
      @click.prevent="popClose('gift-get')"
      ref="gift-get"
    >
      <div class="popBody" @click.stop>
        <div>
          <div class="icon">
            <div class="orange">
              <font-awesome-icon
                icon="fa-solid fa-exclamation"
                size="3x"
              ></font-awesome-icon>
            </div>
          </div>
          <span class="title">確定兌換 {{ reward.rewardName }} ？</span>
          <span v-if="isMM">兌換後將無法取消，請確認已出示給現場人員</span>
          <span v-else>兌換後將無法取消，請確認是否兌換</span>
        </div>
        <div class="buttons">
          <button @click.prevent="popClose('gift-get')">
            <span>取消</span>
          </button>
          <button @click.prevent="getGift">
            <span>確定兌換</span>
          </button>
        </div>
      </div>
    </section>

    <!-- 未驗證會員popup -->
    <section
      id="popNotice"
      class="pop notice"
      @click.prevent="popClose('notice')"
      ref="notice"
    >
      <div class="popBody" @click.stop>
        <div>
          <div class="icon">
            <div class="orange">
              <font-awesome-icon
                icon="fa-solid fa-exclamation"
                size="3x"
              ></font-awesome-icon>
            </div>
          </div>
          <span class="title">未驗證會員</span>
          <span>前往驗證會員，享有完整服務</span>
        </div>
        <div class="buttons">
          <button @click.prevent="popClose('notice')">
            <span>取消</span>
          </button>
          <button @click.prevent="goRegistration">
            <span>前往驗證</span>
          </button>
        </div>
      </div>
    </section>

    <!-- 兌換成功popup -->
    <section
      id="popSuccess"
      class="pop notice"
      @click.prevent="popClose('redeem-success')"
      ref="redeem-success"
    >
      <div class="popBody" @click.stop>
        <div>
          <div class="icon">
            <font-awesome-icon
              icon="fa-regular fa-circle-check"
              size="5x"
            ></font-awesome-icon>
          </div>
          <span class="title">{{ selectedReward.rewardName }}兌換成功</span>
        </div>
        <button @click.prevent="popClose('redeem-success')">
          <span>確認</span>
        </button>
      </div>
    </section>

    <!-- 錯誤訊息popup -->
    <section
      id="popError"
      class="pop notice"
      @click="popClose('error')"
      ref="error"
    >
      <div class="popBody" @click.stop>
        <div>
          <div class="icon">
            <font-awesome-icon
              icon="fa-regular fa-circle-xmark"
              size="5x"
            ></font-awesome-icon>
          </div>
          <span class="title">{{ errorMessage }}</span>
          <span>請稍後重試</span>
        </div>
        <div class="buttons">
          <button @click="popClose('error')">
            <span>確認</span>
          </button>
        </div>
      </div>
    </section>
  </fragment>
</template>
<script>
import moment from 'moment-timezone';
import { mapState } from 'vuex';

export default {
  name: 'StampCards',
  props: {
    stampCards: {
      type: Array,
      default: () => [],
    },
    stamp: {
      type: Object,
      default: () => {},
    },
    selectedStampCard: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['setSelectedStampCard', 'updateStamp'],
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      reward: {},
      selectedReward: {},
      spin: false,
      isInitialized: false,
      errorMessage: '',
    };
  },
  computed: {
    ...mapState(['user', 'userInfo']),
    ...mapState('portal', ['portalInfo']),
    merchantId() {
      return this.user.user?._json?.merchantId || this.userInfo.merchantId;
    },
    stampCardDesc() {
      if (this.stamp.stampCardDesc && typeof this.stamp.stampCardDesc === 'object') {
        return this.stamp.stampCardDesc?.find(
          description => description.title === '優惠說明'
        )?.text;
      } else {
        return this.stamp.stampCardDesc;
      }
    },
    stampCardNotice() {
      if (this.stamp.stampCardDesc && typeof this.stamp.stampCardDesc === 'object') {
        return this.stamp.stampCardDesc?.find(
          description => description.title === '注意事項'
        )?.text;
      } else {
        return '';
      }
      
    },
    stampCardRule() {
      return this.selectedStampCard.loyaltyProgram?.purchase;
    },
    onlineCollection() {
      return this.selectedStampCard.apps?.quickclick;
    },
    isMM () {
      // 有shop_id就MM，沒有是BM
      return this.$route.query.shop_id;
    }
  },
  methods: {
    tsToDate(ts) {
      if (!ts) return ' 年/ 月/ 日';
      return moment(ts * 1000).format('YYYY/MM/DD');
    },
    tsToDatetime(ts) {
      return moment(ts * 1000).format('YYYY-MM-DD HH:mm:ss');
    },
    selectStampCard(stampCard) {
      this.$emit('setSelectedStampCard', stampCard);
      this.popClose('stamps');
    },
    animatePopClose(el) {
      el.children[0].style.animationName = 'popdown';
      setTimeout(() => {
        el.style.display = 'none';
        el.children[0].style.animationName = 'popup';
      }, 500);
    },
    popup(ref) {
      this.$refs[ref].style.display = 'flex';
      document.body.style.overflow = 'hidden';
    },
    popClose(ref) {
      this.animatePopClose(this.$refs[ref]);
      document.body.style.overflow = 'scroll';
    },
    popGift(reward) {
      this.reward = reward;
      this.popup('gift');
    },
    popGiftGet(reward) {
      this.selectedReward = reward;
      this.popup('gift-get');
    },
    popError(message) {
      this.errorMessage = message;
      this.popup('error');
    },
    getGift() {
      this.popClose('gift-get');
      let config = {
        url: `${this.apiHost}/stamps/v1/merchants/${this.merchantId}/stamp-cards/${this.stamp.stampCardId}/claim-reward`,
        method: 'POST',
        data: {
          uuid: this.user.userId,
          rewardId: this.selectedReward.rewardId,
        },
      };
      this.$http(config)
        .then(res => {
          console.log(res);
          this.$emit('updateStamp');
          this.popClose('gift');
          this.popup('redeem-success');
        })
        .catch(err => {
          console.log(err);
          this.popError('兌換失敗');
        });
    },
    checkUnAvailableNumOfStamp(reward) {
      if (Number(this.stamp.numOfStamps) < Number(reward.rewardRequirement)) {
        return true;
      } else {
        return false;
      }
    },
    checkUnAvailableTime() {
      if (Math.floor(Date.now() / 1000) > this.stamp.redeemEndTime) {
        return true;
      } else {
        return false;
      }
    },
    checkAvailableToGet(reward) {
      let numOfStamps = this.stamp?.numOfStamps ? typeof this.stamp.numOfStamps === 'number' ? this.stamp.numOfStamps : Number(this.stamp.numOfStamps.replaceAll(',', '')) : 0;
      let rewardRequirement = reward?.rewardRequirement ? typeof reward.rewardRequirement === 'number' ? this.stamp.numOfStamps : Number(reward.rewardRequirement.replaceAll(',', '')) : 0 ;

      if (numOfStamps >= rewardRequirement) {
        return true;
      } else {
        return false;
      }
    },
    goTo(path) {
      this.$router.push({ path, query: this.$route.query });
    },
    goRegistration() {
      qcsso
        .init({
          appId: this.portalInfo.sso.appId,
          params: { referralCode: this.$route.query.referralCode || '' },
        })
        .then(() => {
          qcsso.login({
            redirectUrl: window.location,
          });
        });
    }
  },
};
</script>
