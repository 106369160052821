<template>
  <a class="verify" @click.prevent="goRegistration">
    <span>完成會員驗證享有完整功能</span>
    <span>前往驗證</span>
    <font-awesome-icon
      icon="fa-solid fa-chevron-right"
      size="sm"
    ></font-awesome-icon>
  </a>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'VerificationButton',
  data() {
    return {};
  },
  computed: {
    ...mapState('portal', ['portalInfo']),
  },
  mounted() {},
  methods: {
    goRegistration() {
      qcsso
        .init({
          appId: this.portalInfo.sso.appId,
          params: { referralCode: this.$route.query.referralCode || '' },
        })
        .then(() => {
          qcsso.login({
            redirectUrl: window.location,
          });
        });
    },
  },
};
</script>
